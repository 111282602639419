.group_card {
	margin-bottom: 60px; /* Adjust the bottom margin as needed */
  }
  
  /* ...other styles... */
  
  .d-flex.justify-content-end.mb-2 {
	margin-top: 5px; /* Add some top margin to the button container */
  }
  
