.indiv-group-page {
  background-color: #ffe5d9;
  padding: 20px;
  border-radius: 8px;
}

.header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
}

.header h1 {
  color: #ff7f50;
  font-size: 24px;
  margin: 0;
}

.role-info {
  display: flex;
  align-items: center;
}

.role-info span {
  margin-right: 10px;
}

.group-card {
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.group-title {
  background-color: #ff7f50;
  color: #fff;
  padding: 15px;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}

.group-recipes {
  padding: 20px;
}

.recipe-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  border-radius: 8px;
  margin-bottom: 10px;
  background-color: #fff;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.recipe-info {
  display: flex;
  align-items: center;
}

.recipe-image {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 50%;
  margin-right: 10px;
}

.recipe-name {
  font-size: 18px;
  font-weight: bold;
}

.recipe-actions {
  display: flex;
  align-items: center;
}

.view-details-btn,
.remove-btn {
  margin-left: 10px;
}

.grocery-list-btn {
  display: flex;
  justify-content: flex-end;
  margin-top: 20px;
}

.group-stats-card {
  margin-top: 20px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
}

.group-stats-title {
  background-color: #ff7f50;
  color: #fff;
  padding: 15px;
  font-size: 20px;
  border-top-left-radius: 8px;
  border-top-right-radius: 8px;
}