.fun-fact {
  background-color: #ffe5d9; /* Light peach background */
  border-radius: 8px;
  padding: 20px;
  text-align: center;
  margin: 20px;
  color: #333;
}

.header {
  color: #ff7f50; /* Coral color */
}

.paragraph {
  font-size: 18px;
  line-height: 1.6;
}
