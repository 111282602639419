@import url('https://fonts.googleapis.com/css2?family=Averia+Serif+Libre&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Roboto:wght@400;700&display=swap');

.homepage {
	background-image: url('../assets/cooking.jpg');
	background-size: cover;
	background-position: center;
	height: 100vh;
	text-align: center;
	display: flex;
	align-items: center;
	justify-content: center;
	margin-top: -20px;
}

.motto-text {
	color: white;
	font-size: 2em;
	text-shadow: 2px 2px 8px rgba(0, 0, 0, 0.7);
	font-family: 'Averia Serif Libre';
}

.get-started {
	font-family: 'Roboto', sans-serif;
	padding: 10px 20px;
	font-size: 1em;
	background-color: #62bff0;
	color: white;
	border: none;
	border-radius: 5px;
	cursor: pointer;
	transition: background-color 0.3s;
	margin-top: 4em;
}

.get-started:hover {
	background-color: #417e9f;
}